<template>
    <div class="form-container">
        <v-form v-model="isValid">
            <vertical-form-table v-model="form" v-bind="{ items }"></vertical-form-table>

            <page-section class="page-section--xxs pb-0">
                <terms-of-agreements-short v-model="form._terms" @isValid="(isValid) => (isTermsValid = isValid)" />
            </page-section>

            <div class="btn-wrap btn-wrap--lg">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <v-btn x-large color="primary" class="w-100 min-w-sm-160px" v-bind="{ disabled }" @click="$emit('save')">Submit</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <!-- <div class="table-style table-style--default">
            <myinfo-modify-row label="이름">
                {{ form.meta.name }}
            </myinfo-modify-row>

            <myinfo-modify-row label="연락처">
                <div class="mw-lg-500px">
                    {{ form.meta.phone }}
                </div>
            </myinfo-modify-row>
            <myinfo-modify-row label="이메일">
                <div class="mw-lg-500px">
                    {{ form.meta.email }}
                </div>
            </myinfo-modify-row>
            <myinfo-modify-row label="제목">
                <v-text-field v-model="form.subject" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
            </myinfo-modify-row>
            <myinfo-modify-row label="내용">
                <v-textarea v-model="form.content" outlined persistent-placeholder hide-details />
            </myinfo-modify-row>
        </div>

        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">취소</span></v-btn>
                <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">문의하기</v-btn>
            </div>
        </div> -->
    </div>
</template>

<script>
import { initUser, rules } from "@/assets/variables";

import PageSection from "@/sets/styles/pages/page-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";
// import ImagesInput from "@/components/console/dumb/images-input.vue";
// import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";

import api from "@/api";

const items = [
    {
        key: "meta.name",
        term: "Name",
        type: "text",
        required: true,
        placeholder: "Type a name.",
        // rules: rules.name,
    },
    {
        key: "meta.company",
        term: "Company",
        type: "text",
        required: true,
        placeholder: "Type a company name.",
    },
    {
        key: "meta.phone",
        term: "Mobile",
        type: "phone",
        required: true,
    },
    {
        key: "meta.email",
        term: "E-mail Address",
        type: "email",
        required: true,
        placeholder: "E-mail address of a person in charge",
        // rules: rules.email,
    },
    {
        key: "meta.message",
        term: "Message",
        type: "textarea",
        required: true,
        placeholder: "Type an inquiry message.",
    },
    {
        key: `meta.file`,
        term: "Attachment",
        type: "file",
        required: false,
        placeholder: "Search",
    },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        TermsOfAgreementsShort,
        // ImagesInput,
        // MyinfoModifyRow,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            work: null,
            form: this.$props.value,

            // form: initUser(),
            items,
            isValid: false,
            isTermsValid: undefined,
        };
    },
    computed: {
        _work() {
            return this.$route.query._work;
        },

        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    // mounted() {
    //     this.init();
    // },
    watch: {
        value() {
            this.board = this.value;
        },
    },
    methods: {
        async init() {
            const { _work: _id } = this;
            const { work } = await api.v1.auction.works.get({ _id });
            this.work = work;
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>
