var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('terms-dialog', _vm._b({
    on: {
      "isLoaded": _vm.setTermsList,
      "input": function (value) {
        return _vm.$emit('input', value);
      },
      "isValid": function (isValid) {
        return _vm.$emit('isValid', isValid);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          isValid = _ref.isValid;
        return [_c('div', _vm._g({
          staticClass: "cursor-pointer"
        }, on), [_c('v-card', {
          attrs: {
            "outlined": "",
            "tile": ""
          }
        }, [_c('v-layout', {
          attrs: {
            "justify-center": ""
          }
        }, [_c('div', {
          staticClass: "px-16px py-8px px-md-20px"
        }, [_vm._l(_vm.termsList, function (terms) {
          return [terms.code == 'privacy-agree' ? _c('v-checkbox', {
            key: terms._id,
            staticClass: "my-8px my-md-12px",
            attrs: {
              "value": _vm.selected.includes(terms._id),
              "hide-details": "",
              "color": "primary",
              "readonly": ""
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function () {
                return [_vm._v(" I consent to collection and use of personal information "), _c('span', {
                  staticClass: "pl-4px",
                  class: [terms.necessary ? 'red--text' : 'grey--text text--lighten-1']
                }, [_vm._v("(" + _vm._s(terms.necessary ? "required" : "optional") + ")")])];
              },
              proxy: true
            }], null, true)
          }) : _vm._e()];
        })], 2)])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'terms-dialog', {
    params: _vm.params
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }