<template>
    <terms-dialog v-model="selected" v-bind="{ params }" @isLoaded="setTermsList" @input="(value) => $emit('input', value)" @isValid="(isValid) => $emit('isValid', isValid)">
        <template #activator="{ on, isValid }">
            <div v-on="on" class="cursor-pointer">
                <!-- <div class="pb-12px">
                    <v-checkbox color="primary" label="I fully agree to the terms and conditions." :value="isValid" hide-details readonly class="v-size--large" />
                </div> -->
                <v-card outlined tile>
                    <v-layout justify-center>
                        <div class="px-16px py-8px px-md-20px">
                            <template v-for="terms in termsList">
                                <v-checkbox v-if="terms.code == 'privacy-agree'" :key="terms._id" :value="selected.includes(terms._id)" hide-details color="primary" readonly class="my-8px my-md-12px">
                                    <template #label>
                                        I consent to collection and use of personal information
                                        <!-- I agree with {{ terms.subject }}. -->
                                        <span class="pl-4px" :class="[terms.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "required" : "optional" }})</span>
                                    </template>
                                </v-checkbox>
                            </template>
                        </div>
                    </v-layout>
                </v-card>
            </div>

            <!-- 상세내용 개별스타일 -->
            <!-- <div>
                <div class="pb-12px">
                    <v-checkbox color="primary" label="I fully agree to the terms and conditions." :value="isValid" hide-details class="v-size--large" />
                </div>
                <v-card outlined tile>
                    <div class="pa-16px pa-md-20px">
                        <div v-for="(terms, index) in termsList" :key="terms._id" class="line-height-11" :class="{ 'mt-6px mt-md-10px': index != 0 }">
                            <v-checkbox :value="selected.includes(terms._id)" hide-details color="primary" class="d-inline-block">
                                <template #label>
                                    <div class="d-inline">
                                        I agree with <span v-on="on" class="text-decoration-underline pl-4px cursor-pointer" @click.prevent>{{ terms.subject }}</span>.
                                        <span class="pl-4px" :class="[terms.necessary ? 'red--text' : 'grey--text text--lighten-1']">({{ terms.necessary ? "required" : "optional" }})</span>
                                    </div>
                                </template>
                            </v-checkbox>
                        </div>
                    </div>
                </v-card>
            </div> -->
        </template>
    </terms-dialog>
</template>

<script>
import TermsDialog from "./terms-dialog.vue";

export default {
    components: {
        TermsDialog,
    },
    props: {
        value: { type: Array, default: () => [] },
        params: { type: Object, default: () => ({}) },
    },
    data: () => ({
        termsList: [],
        selected: [],
    }),
    methods: {
        setTermsList(termsList) {
            this.termsList = termsList;
        },
    },
};
</script>

<style></style>
