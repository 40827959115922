var render = function render(){
  var _vm$btnAttrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    class: _vm.$attrs.className,
    attrs: {
      "placeholder": _vm.label ? `${_vm.label} 우편번호` : '우편번호',
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": _vm.search
    },
    model: {
      value: _vm.postcode,
      callback: function ($$v) {
        _vm.postcode = $$v;
      },
      expression: "postcode"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    dense: _vm.dense
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "w-100 w-sm-80px",
    class: (_vm$btnAttrs = _vm.btnAttrs) === null || _vm$btnAttrs === void 0 ? void 0 : _vm$btnAttrs.className,
    attrs: {
      "x-large": _vm.dense == false ? true : false,
      "color": "grey"
    },
    on: {
      "click": _vm.search
    }
  }, 'v-btn', {
    btnAttrs: _vm.btnAttrs,
    dense: _vm.dense
  }, false), [_vm._v("검색")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    class: _vm.$attrs.className,
    attrs: {
      "placeholder": _vm.label ? `${_vm.label} 기본주소` : '기본주소',
      "hide-details": "",
      "readonly": ""
    },
    on: {
      "click": _vm.search
    },
    model: {
      value: _vm.address1,
      callback: function ($$v) {
        _vm.address1 = $$v;
      },
      expression: "address1"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    dense: _vm.dense
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    class: _vm.$attrs.className,
    attrs: {
      "placeholder": _vm.label ? `${_vm.label} 상세주소` : '상세주소'
    },
    model: {
      value: _vm.address2,
      callback: function ($$v) {
        _vm.address2 = $$v;
      },
      expression: "address2"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs, {
    rules: _vm.rules,
    dense: _vm.dense
  }), false)), _c('daum-postcode', {
    ref: "daum-postcode",
    on: {
      "change": _vm.processAddressData
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }